// Variables
$secondary: #383838;

// General Styling
h1, h2, h3, h4, h5, h6, span, p, li {
    color: $secondary;
}

.v-main {
    min-height: 100vh;

    > .v-main__wrap {
        display: flex;
    }
}

.dashboard-card {
    .dashboard-menu-icon {
        font-size: 40px !important;
    }
}

.img-cont {
    position: relative;

    img {
        max-width: 100%;
    }
}

//Nav Drawer
.v-navigation-drawer {
    &.v-navigation-drawer--mini-variant {
        width: 100px !important;
    }

    .v-navigation-drawer__content {
        position: relative;

        .chevron-drawer {
            position: fixed;
            bottom: 120px;
            right: 10px;
        }
    }

    &.v-navigation-drawer--mini-variant {
        .v-list-item__icon, .v-list-item__icon {
            margin: auto;
        }
    }

    &:not(.v-navigation-drawer--mini-variant) {
        .v-list-item {
            padding: 0;
        }

        .v-list-item__icon, .v-list-item__icon {
            margin: 12px 20px 12px 0;
            padding-left: 38px;
        }

        .v-navigation-drawer__append {
            .v-list-item__icon {
                padding-left: 10px;
            }
        }
    }
}

// Form Styles
.v-form {
    .forgot-password {
        text-align: right;

        a {
            text-decoration: none;
            font-size: 12px;
            line-height: 15px;
        }
    }
}

.v-dialog {
    label {
        font-size: 14px;
        font-weight: 600;
        color: $secondary;
    }
    textarea {
        line-height: 1.5rem !important;
    }
}

.v-main.login-remember {
    background:  url("/images/qf-connector-background.png");
    background-size: cover;
    background-position: top left;
    background-repeat: no-repeat;

    .container--fluid {
        justify-content: center;
        align-items: center;
    }

    .v-form {
        position : absolute;
        background: white;
        max-width: 430px;
        width: 100%;
        z-index  : 10;
    }
}

// Table Styles
.v-data-table .v-toolbar__content {
    padding: 0 !important;
}

.v-data-table {
    margin-bottom: 30px;

    table {
        padding: 35px 28px;

        .v-data-table-header {
            th {
                > span {
                    font-size: 20px;
                    line-height: 24px;
                }

                .v-icon {
                    float: right;
                }
            }
        }

        tr {
            th:not(:last-child), td:not(:last-child) {
                border-right: thin solid rgba(0,0,0,.12);
            }

            th:first-child, td:first-child {
                padding: 0 !important;
            }

            td {
                font-size: 14px;
                line-height: 21px;
                font-weight: 600;
                padding: 10px 15px !important;
            }

            .v-icon {
                font-size: 18px !important;
            }

            &.v-data-table__expanded__row {
                td {
                    border-bottom: 0 !important;
                    border-right: 0 !important;
                    border-left: 0 !important;
                }
            }

            &.v-data-table__expanded__content {
                box-shadow: none !important;
            }
        }

        .v-data-table__expanded {
            .expanded-details {
                .row {
                    div {
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                    }

                    div:not(:last-child){
                        position: relative;

                        // &:after {
                        //     content: "\2022";
                        //     display: inline-block;
                        //     position: absolute;
                        //     right: 5px;
                        // }
                    }
                }
            }

            span {
                font-size: 14px;
                line-height: 21px;
                font-weight: 400;
            }
        }
    }
}

.v-expansion-panel-content {
    padding: 10px 25px 30px;

    ul {
        padding-left: 0;

        li {
            list-style: none;
        }
    }
    > div {
        padding: 0;
    }
    table {
        padding: 0;
    }
}
